@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image img {
  border-radius: 1rem !important;
}

.navbar {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

@media (max-width: 575.98px) {
  .is-size-1 {
    font-size: 2.3rem !important;
  }

  .greeting {
    margin-top: .5rem !important;
  }

  .section-1 {
    padding: 2rem 1.5rem;
  }

  .main {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .navbar {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}

.navbar-burger-toggle + .navbar-burger {
  margin-top: -3.25em;
}

.navbar-burger-toggle:checked + .navbar-burger span:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.navbar-burger-toggle:checked + .navbar-burger span:nth-child(2) {
  opacity: 0;
}

.navbar-burger-toggle:checked + .navbar-burger span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.navbar-burger-toggle:checked ~ .navbar-menu {
  display: block;
}

.navbar-menu {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}